import styled from 'styled-components'
import { Spacing } from 'atomic'

export const ContactUsIconWrapper = styled.div`
  margin-right: ${Spacing.Medium};
  text-align: center;
  font-size: 14px;
`

export const PhoneIconWrapper = styled.div`
  font-size: 12px;
`

export const AlreadyRegisterStyled = styled.div`
  margin: 0 ${Spacing.Medium};

  .col-wrapper {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 20px;
      align-self: center;
    }
  }

  svg {
    display: none;
  }
`
