import { Body, Button, Col, H2, Row } from 'atomic'

import { Modal } from 'atomic/legacy/obj.modal'

import React from 'react'

import { AlreadyRegisterStyled } from './atendimento-cep-modal.component.style'

interface AlreadyRegisterProps {
  open: boolean
  onClose: () => void
  buttonText: string
  messageText: string
}

export const AtendimentoCepModal: React.FunctionComponent<AlreadyRegisterProps> = props => {
  return (
    <AlreadyRegisterStyled>
      <Modal small onClose={props.onClose} opened={props.open}>
        <Row mb>
          <Col xs={12} className="col-wrapper">
            <H2>Atenção</H2>
            <Body>{props.messageText}</Body>
            <Button external target="_blank" onClick={props.onClose}>
              {props.buttonText}
            </Button>
          </Col>
        </Row>
      </Modal>
    </AlreadyRegisterStyled>
  )
}
