import React, { useState } from 'react'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import axios from '@root/src/utils/base-axios.js'

import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { graphql } from 'gatsby'
import { AccordionsContainer } from './styles'

import { Form, Validators } from 'atomic/legacy/obj.form'
import { TextFieldNew } from '@root/../atomic/legacy/atm.text-field-new'
import { FloatingLabelNew } from 'atomic/legacy/atm.floating-label-new/floating-label-new.component'

import Accordion from 'site/src/components/Accordion'
import Title from 'site/src/components/Title'
import { Button } from 'atomic'
import InfoContato from '@root/src/components/InfoContato'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { getClientAndDoctorUrl } from 'utils/url'

import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core'

import * as S from './styles'
import { AtendimentoCepModal } from '@root/src/components/mol.atendimento-cep-modal/atendimento-cep-modal.component'

export default props => {
  const theme = useTheme()
  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  const informacoesContato = props.data.informacoesContato.metadata
  const [showModalAlreadyRegister, setShowModalAlreadyRegister] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)

  const [expanded, setExpanded] = useState(false)
  const { metadata } = props.data.cosmicjsLandingPages
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleSubmit = async formData => {
    const cepToSearch = formData.data.cep.replace('-', '')

    const hasCover = await fetchData(cepToSearch)

    if (hasCover) {
      setModalMessage('O cep informado está dentro da área de cobertura do Weinmann Serdil')
      setShowModalAlreadyRegister(true)
    } else {
      setModalMessage('O cep informado não está dentro da área de cobertura do Weinmann Serdil')
      setShowModalAlreadyRegister(true)
    }
  }

  const fetchData = async cep => {
    try {
      let string = ''

      switch (process.env.GATSBY_MARCA) {
        case 'FLEURY':
          string = '?marca=FL'
          break
        case 'FM':
          string = '?marca=FM'
          break
        case 'WEINMANN':
          string = '?marca=WE'
          break
        default:
          return ''
      }

      const data = await axios.get(`/agendar/v1/agendamento-movel/verificar-cep/${cep}${string}`)

      return data.data.cepAtendido
    } catch {
      return false
    }
  }

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <AtendimentoCepModal
        open={showModalAlreadyRegister}
        onClose={async () => setShowModalAlreadyRegister(false)}
        buttonText={'Fechar'}
        messageText={modalMessage}
      />
      <SEO
        socialMedia={{
          image: '',
          canonicalUrl: '',
          title: metadata.titulo_pagina,
          description: 'description'
        }}
      />
      <IndexLayout location={props.location}>
        <Container maxWidth="md">
          <TitleWithBreadcrumbRow
            addtionalDictionary={{
              '/coleta-domiciliar': metadata.titulo_pagina
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Title variant="h5">{metadata.titulo_pagina}</Title>
                  <S.Description
                    dangerouslySetInnerHTML={{
                      __html: metadata.descricao
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <S.ImageContainer>
                    <S.ImagePrincipal src={metadata.imagem.imgix_url} />
                  </S.ImageContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <S.CardsContainer>
                <div className="left">
                  <img src={metadata.cards[0].imagem.imgix_url} alt="imagem card" />
                  <span className="titulo">{metadata.cards[0].descricao}</span>
                  <span>
                    {metadata.cards[0].subtitulo}
                    <span className="subtitulo">{metadata.cards[0].subtitulo}</span>
                  </span>
                </div>
                <div className="middle">
                  <img src={metadata.cards[1].imagem.imgix_url} alt="imagem card" />
                  <span className="titulo">
                    {metadata.cards[1].descricao}
                    <span className="subtitulo">{metadata.cards[1].subtitulo}</span>
                  </span>
                </div>
                <div className="right">
                  <img src={metadata.cards[2].imagem.imgix_url} alt="imagem card" />
                  <span className="titulo">
                    {metadata.cards[2].descricao}
                    <span className="subtitulo">{metadata.cards[2].subtitulo}</span>
                  </span>
                </div>
              </S.CardsContainer>
            </Grid>
            <Grid item xs={12}>
              {/* <S.CepContainer>
                <span className="description">
                  Você pode conferir se o serviço está disponível na sua localidade. Basta inserir o
                  seu CEP no campo abaixo:
                </span>
                <div>
                  <div>
                    <Form id="form-cadastro-medico" onSubmit={handleSubmit}>
                      <Form.Field
                        name="cep"
                        validators={[Validators.IsNotOnlySpaces('Valor invalido')]}
                      >
                        <FloatingLabelNew label={'Digite seu CEP'}>
                          <TextFieldNew
                            id="text-field-medic-cep"
                            type={'text'}
                            kind={'zip-code'}
                            autoComplete="postal-code"
                          />
                        </FloatingLabelNew>
                      </Form.Field>
                    </Form>
                  </div>
                </div>
              </S.CepContainer> */}
            </Grid>
            <span id="regioes-cobertura"></span>
            <Grid item xs={6}>
              <S.TopicsContainer>
                <Title variant="h6" noLine>
                  Serviços disponíveis
                </Title>
                <ul>
                  {metadata.servicos_disponiveis.map(topico => (
                    <li>{topico.servico}</li>
                  ))}
                </ul>
              </S.TopicsContainer>
            </Grid>
            <Grid item xs={6}>
              <S.TopicsContainer>
                <Title variant="h6" noLine>
                  Regiões de cobertura
                </Title>
                <ul>
                  {metadata.regioes_cobertura.map(topico => (
                    <li>{topico.regiao}</li>
                  ))}
                </ul>
              </S.TopicsContainer>
            </Grid>

            <Grid item xs={12}>
              <S.ComoFunciona>
                <div className="titulo">
                  <img src={metadata.como_funciona.carrinho.imgix_url} alt="carrinho" />
                  <Title variant="h5" noLine>
                    Como funciona
                  </Title>{' '}
                </div>
              </S.ComoFunciona>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <S.InfoContato1>
                    {metadata.como_funciona.horarios.map(horario => (
                      <>
                        <Title noMargin variant="h7" noLine>
                          {horario.titulo}
                        </Title>
                        <S.Text>{horario.descricao}</S.Text>
                      </>
                    ))}
                  </S.InfoContato1>
                </Grid>
                {!downOfSm && (
                  <Grid item sm={1}>
                    <S.VerticalSeparator />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <S.InfoContato2>
                    <InfoContato
                      informacoesContato={informacoesContato}
                      subtitulo={'Para agendar, é só falar com a gente no telefone'}
                      titulo={'Agendamento'}
                    />
                  </S.InfoContato2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <AccordionsContainer>
          <Container maxWidth="md">
            <Title variant="h5">Perguntas frequentes</Title>
            {metadata.faq.map(({ question, answer }, index) => {
              return (
                <Accordion
                  type="medical-area"
                  bgWhite
                  key={index}
                  slug={index.toString()}
                  title={`${index + 1}. ${question}`}
                  expanded={expanded}
                  handleChange={handleChange}
                >
                  <p dangerouslySetInnerHTML={{ __html: answer }} variant="body1" />
                </Accordion>
              )
            })}
          </Container>
        </AccordionsContainer>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export const query = graphql`
  query ColetaDomiciliar {
    informacoesContato: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
      metadata {
        telefoneContato
        chatOnlineLink
        agendamentoWhatsappLink
        telefoneContatoMedico
        label_telefone
        label_whatsapp
        chatOnlineLinkMedico
        agendamentoWhatsappLinkMedico
        texto_fale_conosco
        show_whatsapp_contactus
      }
    }
    cosmicjsLandingPages(slug: { eq: "coleta-domiciliar-lp" }) {
      id
      slug
      metadata {
        titulo_pagina
        descricao
        imagem {
          url
          imgix_url
        }
        cards {
          descricao
          subtitulo
          imagem {
            url
            imgix_url
          }
        }
        servicos_disponiveis {
          servico
        }
        regioes_cobertura {
          regiao
        }
        como_funciona {
          titulo
          carrinho {
            url
            imgix_url
          }
          horarios {
            titulo
            descricao
          }
        }
        faq {
          question
          answer
        }
      }
    }
  }
`
